import React from 'react';
import ReactDOM from 'react-dom';
// import 'antd/dist/antd.less';


import {App} from './app';
import './index.less';

ReactDOM.render( <App/>,
  document.getElementById('root')
);



